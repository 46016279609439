import React from 'react';
import { Routes,Route, useNavigate, NavLink, Link } from 'react-router-dom';
import Authuser from '../api/Authuser';
import Protected from '../components/Protected';
import Login from '../pages/login/Login';
import Register from '../pages/register/Register';
import Intro from '../pages/intro/Intro';
import Dashboard from '../pages/dashboard/Dashboard';
import Market from '../pages/market/Market';
import pairchangeone from '../pages/market/Pairchangeone';
import Pairchangetwo from '../pages/market/Pairchangetwo';
import Profile from '../pages/profile/Profile';
import Refferearn from '../pages/profile/Refferearn';
import Kycverification from '../pages/profile/Kycverification';
import News from '../pages/profile/News';
import Transactionhistory from '../pages/profile/Transactionhistory';
import Banking from '../pages/profile/Banking';
import Graph from '../pages/graph/Graph';
import Orders from '../pages/orders/Orders';
import Buysell from '../pages/buysell/Buysell';
import Wallet from '../pages/wallet/Wallet';
import Addfavourite from '../pages/addfavourites/Addfavourite';
import Addfavouriteone from '../pages/addfavourites/Addfavouriteone';
import Addfavouritetwo from '../pages/addfavourites/Addfavouritetwo';

export default function Auth() { 

  const {bUrl} = Authuser();
  const navigate = useNavigate();
  const uflag = localStorage.getItem('flag');

  return (
    <>

<div className="internet-connection-status" id="internetStatus"></div>
<div className="header-area shadow-sm" id="headerArea" style={{background:'#000035'}}>
  <div className="container">
  <div className="header-content header-style-three position-relative d-flex align-items-center justify-content-between">                           
        <div className="navbar--toggler" id="affanNavbarToggler" style={{background:'#000035'}}>
          <div className="span-wrap">
              <img src={`${bUrl}storage/${uflag}`} />
          </div>
        </div>
        <div className="logo-wrapper"><a href="#"><img src="/assets/images/logo.png" alt=""/></a></div>
        <div className="user-profile-wrapper"><Link to="/profile"><img src="/assets/images/PS3.png" alt="" style={{width:'40px', height:'40px'}} /></Link></div>
      </div>
  </div>
</div> 

<Routes>
  <Route path='/' element={<Intro />} /> 
  <Route path='/login' element={<Login />} />
  <Route path='/register' element={<Register />} />
  <Route path='/dashboard' element={<Protected Component={Dashboard} />} />
  <Route path='/market' element={<Protected Component={Market} />} />
  <Route path='/pchangeone/market/:pairname' element={<Protected Component={pairchangeone} />} />
  <Route path='/pchangetwo/market/:pairname' element={<Protected Component={Pairchangetwo} />} />
  <Route path='/profile' element={<Protected Component={Profile} />} />
  <Route path='/kyc-verification' element={<Protected Component={Kycverification} />} />
  <Route path='/reffer-earn' element={<Protected Component={Refferearn} />} />
  <Route path='/news' element={<Protected Component={News} />} />
  <Route path='/transaction-history' element={<Protected Component={Transactionhistory} />} /> 
  <Route path='/bank-payment' element={<Protected Component={Banking} />} /> 
  <Route path='/graph/:coincode/:pair' element={<Protected Component={Graph} />} />  
  <Route path='/orders' element={<Protected Component={Orders} />} />
  <Route path='/buysell/:coincode/:pairname/:tradetype' element={<Protected Component={Buysell} />} />
  <Route path='/wallet' element={<Protected Component={Wallet} />} />
  <Route path='/add-favourites' element={<Protected Component={Addfavourite} />} />
  <Route path='/add-favourites-one/:pairname' element={<Protected Component={Addfavouriteone} />} />
  <Route path='/add-favourites-two/:pairname' element={<Protected Component={Addfavouritetwo} />} />
</Routes>


<div className="footer-nav-area" id="footerNav">
<div className="container px-0">

<div className="footer-nav position-relative">
<ul className="h-100 d-flex align-items-center justify-content-between ps-0">
  <li><NavLink to="/dashboard" className="active">
      <svg xmlns="https://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" className="bi bi-arrow-left-right" viewBox="0 0 16 16">
      <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4H2.5z"/>
      </svg><span >Home</span></NavLink>
  </li>
  <li><NavLink to="/market" >
      <svg xmlns="https://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" className="bi bi-currency-exchange" viewBox="0 0 16 16">
      <path d="M0 5a5.002 5.002 0 0 0 4.027 4.905 6.46 6.46 0 0 1 .544-2.073C3.695 7.536 3.132 6.864 3 5.91h-.5v-.426h.466V5.05c0-.046 0-.093.004-.135H2.5v-.427h.511C3.236 3.24 4.213 2.5 5.681 2.5c.316 0 .59.031.819.085v.733a3.46 3.46 0 0 0-.815-.082c-.919 0-1.538.466-1.734 1.252h1.917v.427h-1.98c-.003.046-.003.097-.003.147v.422h1.983v.427H3.93c.118.602.468 1.03 1.005 1.229a6.5 6.5 0 0 1 4.97-3.113A5.002 5.002 0 0 0 0 5zm16 5.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0zm-7.75 1.322c.069.835.746 1.485 1.964 1.562V14h.54v-.62c1.259-.086 1.996-.74 1.996-1.69 0-.865-.563-1.31-1.57-1.54l-.426-.1V8.374c.54.06.884.347.966.745h.948c-.07-.804-.779-1.433-1.914-1.502V7h-.54v.629c-1.076.103-1.808.732-1.808 1.622 0 .787.544 1.288 1.45 1.493l.358.085v1.78c-.554-.08-.92-.376-1.003-.787H8.25zm1.96-1.895c-.532-.12-.82-.364-.82-.732 0-.41.311-.719.824-.809v1.54h-.005zm.622 1.044c.645.145.943.38.943.796 0 .474-.37.8-1.02.86v-1.674l.077.018z"/>
      </svg><span>Market</span></NavLink></li>
  <li><Link reloadDocument to="/graph/BTC/USDT">
      <svg xmlns="https://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" className="bi bi-arrow-left-right" viewBox="0 0 16 16">
      <path fill-rule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z"/>
      </svg><span>Trades</span></Link></li>
  <li><NavLink to="/orders">
      <svg xmlns="https://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" className="bi bi-basket3" viewBox="0 0 16 16">
      <path d="M5.757 1.071a.5.5 0 0 1 .172.686L3.383 6h9.234L10.07 1.757a.5.5 0 1 1 .858-.514L13.783 6H15.5a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H.5a.5.5 0 0 1-.5-.5v-1A.5.5 0 0 1 .5 6h1.717L5.07 1.243a.5.5 0 0 1 .686-.172zM3.394 15l-1.48-6h-.97l1.525 6.426a.75.75 0 0 0 .729.574h9.606a.75.75 0 0 0 .73-.574L15.056 9h-.972l-1.479 6h-9.21z"/>
      </svg><span>Orders</span></NavLink></li>
  <li className="@yield('fund_active')"><Link to="/wallet">
      <svg xmlns="https://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" className="bi bi-wallet-fill" viewBox="0 0 16 16">
      <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v2h6a.5.5 0 0 1 .5.5c0 .253.08.644.306.958.207.288.557.542 1.194.542.637 0 .987-.254 1.194-.542.226-.314.306-.705.306-.958a.5.5 0 0 1 .5-.5h6v-2A1.5 1.5 0 0 0 14.5 2h-13z"/>
      <path d="M16 6.5h-5.551a2.678 2.678 0 0 1-.443 1.042C9.613 8.088 8.963 8.5 8 8.5c-.963 0-1.613-.412-2.006-.958A2.679 2.679 0 0 1 5.551 6.5H0v6A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-6z"/>
      </svg><span>Wallet</span></Link></li>
</ul>
</div>
</div> 
</div>

    </>
  )
}
